

const prod = {
	WEB_DOMAIN: "https://sprape.compli.tech/",
	REACT_APP_BACKEND_BASE_URL: "https://sprapeql.compli.tech/",
	ACTION_CABLE_URL: "wss://sprapeql.compli.tech/cable"
 };
 
 const dev = {
	WEB_DOMAIN: "http://localhost:3000/",
	REACT_APP_BACKEND_BASE_URL: "http://localhost:3003/",
	ACTION_CABLE_URL: "ws://localhost:3003/cable"
 };

export default process.env.NODE_ENV === 'development' ? dev : prod;
