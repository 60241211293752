import React, { useEffect, useState } from "react";

import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  chakra,
  Box,
  Flex,
  Button,
  Input,
  Select,
  SimpleGrid,
} from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import GlobalFilter from "../ui/GlobalFilter";
import {
  FaAngleRight,
  FaAngleLeft,
  FaAngleDoubleRight,
  FaAngleDoubleLeft,
} from "react-icons/fa";
import { Checkbox } from "./CheckBox";
import CreateScrapePopup from "../ui/CreateScrapePopup";
import EditScrapePopup from "../ui/EditScrapePopup";

export default function SprapeTable({
  data,
  columns,
  isPagination,
  createForm,
  isSelectRows,
  cityLinksList,
  citiesData,
  handleCityChange,
  editScrape,
	createScrape = null
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    pageOptions,
    state,
    canPreviousPage,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    setGlobalFilter,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
			autoResetPage: false,
			autoResetExpanded: false,
			autoResetGroupBy: false,
			autoResetSelectedRows: false,
			autoResetSortBy: false,
			autoResetFilters: false,
			autoResetRowState: false,
			autoResetGlobalFilter: false
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) =>
        isSelectRows
          ? [
              {
                id: "Selection",
                Header: ({ getToggleAllRowsSelectedProps }) => (
                  <Checkbox {...getToggleAllRowsSelectedProps()} />
                ),
                Cell: ({ row }) => (
                  <Checkbox {...row.getToggleRowSelectedProps()} />
                ),
              },
              ...columns,
            ]
          : columns
      );
    }
  );

  const { globalFilter, pageIndex, pageSize } = state;
  const [copiedLinks, setCopiedLinks] = useState([]);

  useEffect(() => {
    setCopiedLinks(selectedFlatRows.map((i) => i.original.node.id));
  }, [selectedFlatRows]);

  return (
    <>
      <Flex>
        <Box>
          {cityLinksList && (
						<Box mr='2'>
							<Select onChange={handleCityChange} id="selectCity" mr={4}>
								<option value={0}>All City Links</option>
								{citiesData?.cities.map((city) => (
									<option key={city.id} value={city.id}>
										{city.name}
									</option>
								))}
							</Select>
						</Box>
          )}
        </Box>
        <Box flex="1">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </Box>
        { createForm && <Box ml='2'>{createForm}</Box> }
        {copiedLinks.length > 0 && (
          <Flex gap={2}>
            <Box ml='2'>
              <CreateScrapePopup
                title={"Create Scrape of " + copiedLinks.length + " Links"}
                selectedLinks={copiedLinks}
              />
            </Box>
            <Box>
              <EditScrapePopup selectedLinks={copiedLinks} />
            </Box>
          </Flex>
        )}
				{ createScrape && <Box ml='2'> {createScrape} </Box> }
      </Flex>
      <Box mt={4} borderRadius="md" borderWidth="1px" p="4">
        <Box overflow="auto">
          <Table size="sm" {...getTableProps()}>
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <chakra.span pl="4">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <TriangleDownIcon aria-label="sorted descending" />
                          ) : (
                            <TriangleUpIcon aria-label="sorted ascending" />
                          )
                        ) : null}
                      </chakra.span>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
        {isPagination && (
          <SimpleGrid columns={2} mt={10} spacing={10}>
            <Flex h={8} alignItems={"center"} justifyContent={"start"}>
              Rows per page
              <Select
                value={pageSize}
                variant="unstyled"
                width="initial"
                display="inline-block"
                mx={2}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 25, 50, 100].map((i) => (
                  <option key={i} value={i}>
                    {i}
                  </option>
                ))}
              </Select>
            </Flex>
            <Flex h={8} alignItems={"center"} justifyContent={"end"}>
              <Input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
                size="sm"
                width="60px"
                mr={2}
                placeholder="Go to"
              />
              <Button
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                size="sm"
                mx={2}
              >
                <FaAngleDoubleLeft />
              </Button>
              <Button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                size="sm"
                mr={2}
              >
                <FaAngleLeft />
              </Button>
              <span>
                {pageIndex + 1} of {pageOptions.length}
              </span>
              <Button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                size="sm"
                mx={2}
              >
                <FaAngleRight />
              </Button>
              <Button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                size="sm"
              >
                <FaAngleDoubleRight />
              </Button>
            </Flex>
          </SimpleGrid>
        )}
      </Box>
    </>
  );
}
