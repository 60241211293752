import React from 'react';
import {
  Box,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Heading,
  Skeleton,
} from '@chakra-ui/react';
import { SUMMARY } from '../api';
import { useQuery } from '@apollo/client';
import { FaCity, FaLink, FaSpider } from 'react-icons/fa';

function StatsCard(props) {
  const { title, stat, icon } = props;
  return (
    <Stat
      px={{ base: 2, md: 4 }}
      py={'5'}
      shadow={'xl'}
      border={'1px solid'}
      borderColor={useColorModeValue('gray.800', 'gray.500')}
      rounded={'lg'}
    >
      <Flex justifyContent={'space-between'}>
        <Box pl={{ base: 2, md: 4 }}>
          <StatLabel fontWeight={'medium'} isTruncated>
            {title}
          </StatLabel>
          <Skeleton isLoaded={stat}>
            <StatNumber fontSize={'4xl'} fontWeight={'medium'}>
              {stat || '000'}
            </StatNumber>
          </Skeleton>
        </Box>
        <Box
          my={'auto'}
          mr={5}
          color={useColorModeValue('gray.800', 'gray.200')}
          alignContent={'center'}
        >
          {icon}
        </Box>
      </Flex>
    </Stat>
  );
}

export default function Dashboard() {
  const { data } = useQuery(SUMMARY, {
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <Box>
        <Heading as='h3'>Dashboard</Heading>
        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          py={5}
          spacing={{ base: 5, lg: 8 }}
        >
          <StatsCard
            icon={<FaCity size={'3em'} />}
            title={'City'}
            stat={data?.summary?.cityCount}
          />
          <StatsCard
            icon={<FaLink size={'3em'} />}
            title={'Links'}
            stat={data?.summary?.linkCount}
          />
          <StatsCard
            icon={<FaSpider size={'3em'} />}
            title={'Scrapes'}
            stat={data?.summary?.scrapeCount}
          />
        </SimpleGrid>
      </Box>
    </>
  );
}
