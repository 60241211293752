// import { ReactNode } from 'react';
import { NavLink as Link } from 'react-router-dom';
import {
  Box,
  Flex,
  Button,
  useColorModeValue,
  Stack,
  useColorMode,
} from '@chakra-ui/react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';

const NavLink = ({ href, children }) => (
  <Link to={href}>
    <Box
      px={2}
      py={1}
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('gray.200', 'gray.700'),
      }}
    >
      {children}
    </Box>
  </Link>
);

export default function Nav() {
  const { colorMode, toggleColorMode } = useColorMode();

  const activeNavStyle = {
    textDecoration: 'underline',
  };

  return (
    <>
      <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          {/* <Box>Logo</Box> */}
          <Flex h={8} alignItems={'left'} justifyContent={'space-between'}>
            <NavLink
              href='/'
              style={({ isActive }) => (isActive ? activeNavStyle : undefined)}
            >
              Home
            </NavLink>
            <NavLink
              href='/links'
              style={({ isActive }) => (isActive ? activeNavStyle : undefined)}
            >
              Links
            </NavLink>
            <NavLink href='/scrapes' style={{ textDecoration: 'underline' }}>
              Scrapes
            </NavLink>
          </Flex>

          <Flex alignItems={'center'}>
            <Stack direction={'row'} spacing={7}>
              <Button onClick={toggleColorMode}>
                {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
              </Button>
            </Stack>
          </Flex>

        </Flex>
      </Box>
    </>
  );
}
