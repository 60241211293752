import React from "react";
import { Input } from "@chakra-ui/react";

export default function GlobalFilter({ filter, setFilter }) {
  return (
    <Input
      variant="filled"
      mr={4}
      placeholder="Search Here"
      value={filter || ""}
      onChange={(e) => setFilter(e.target.value)}
    />
  );
}
