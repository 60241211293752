import { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import AuthUser from '../../AuthUser';
import { Dashboard } from '../../components';
import { LinksManager } from '../../components';
import { ScrapesManager } from '../../components';
import { Login } from '../../components';

export function AllRoutes() {

	const Auth = useContext(AuthUser);
 
	return (
		<Routes>
			<Route exact path='/' element={Auth.isAuthorized ? <Dashboard /> : <Navigate to="/login" />}></Route>
			<Route exact path='/links' element={Auth.isAuthorized ? <LinksManager /> : <Navigate to="/login" />}></Route>
			<Route exact path='/scrapes' element={Auth.isAuthorized ? <ScrapesManager /> : <Navigate to="/login" />}></Route>

			<Route exact path='/login' element={ <Login /> }></Route>
		</Routes>
	)
};
