import React, {useState} from 'react'
import { useMutation, useQuery } from "@apollo/client";
import "react-datepicker/dist/react-datepicker.css";
import { SCRAPECREATE, SCRAPES, ALL_CITIES } from "../../api";
import {
   useToast,
   Popover,
   PopoverTrigger,
   PopoverContent,
   PopoverHeader,
   PopoverBody,
   PopoverArrow,
   PopoverCloseButton,
   Box,
   FormControl,
   FormLabel,
   Button,
   Textarea,
   Stack,
   Input,
   Tag,
   Select
 } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { FaPlus } from "react-icons/fa";

export default function CreateScrapePopup({title = <FaPlus />, selectedLinks = null}) {

	const toast = useToast();

	const [scrapeName, setScrapeName] = useState('');
	const [scheduleDate, setScheduleDate] = useState(new Date());
	const [frequency, setFrequency] = useState("daily");
	const [linksFile, setLinksFile] = useState(null);
	const [selectedCityId, setSelectedCityId] = useState(null);

	const {data: allCities} = useQuery(ALL_CITIES,{
	onCompleted: (data) => {
		setSelectedCityId(data.cities[0].id)
	}
	});

	const [scrapeCreate] = useMutation(SCRAPECREATE, {
		context: { hasUpload: true },
		refetchQueries: [SCRAPES],
		onCompleted: (data) => {
			toast({
				title: data.scrapeCreate.message,
				status: "info",
				duration: 5000,
				isClosable: true,
			});
		},
	});

	const saveScrape = () => {
	let enteredLinks = null;
	let urlsInput = document.getElementById("urls").value;
	let urlArr = null

	if (selectedLinks) {
		enteredLinks = selectedLinks.map((i) => parseInt(i));
	}

	if (urlsInput) {
		urlArr = urlsInput
			.split(" ")
			.join("")
			.split(",")
			.filter((l) => l.length > 5);
	}

	if (scrapeName !== ''){
		scrapeCreate({
			variables: {
					name: String(scrapeName),
					linkIds: enteredLinks ? enteredLinks : [],
					frequency: String(frequency),
					scheduledAt: scheduleDate,
					links: urlArr ? urlArr : [],
					linksFile: linksFile,
					cityId: parseInt(selectedCityId)
			},
		});
		document.getElementsByClassName('chakra-popover__close-btn')[0].click()
	} else {
			toast({
				title: "Please, Enter Valid Data",
				status: "error",
				duration: 5000,
				isClosable: true,
			});
	}
	};

	let frequencyData = [
		{
			key: "Every 6 Hours",
			value: "daily4"
		},
		{
			key: "Every 8 Hours",
			value: "daily3"
		},
		{
			key: "Every 12 Hours",
			value: "daily2"
		},
		{
			key: "Daily",
			value: "daily"
		},
		{
			key: "Weekly",
			value: "weekly"
		},
		{
			key: "Monthly",
			value: "monthly"
		},
	]
	
  return (
    <>
      <Box>
      <Popover placement="bottom-end" isLazy>
        <PopoverTrigger>
          <Button colorScheme="blue">
            {title}
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverHeader fontWeight="semibold">
            Create New Scrape
          </PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
          	<Stack spacing="24px">
              <Box>
                <FormControl isRequired>
                  <FormLabel htmlFor="scrapeName"><b>Scrape Name</b></FormLabel>
                  <Input value={scrapeName} onChange={(e) => setScrapeName(e.target.value)}/>
                </FormControl>
              </Box>

							{
								selectedLinks &&
								<Box>
									<Tag size='lg'>{selectedLinks.length + " Links Selected"}</Tag>
								</Box>
							}

							<Box>
								<FormLabel htmlFor="urls"><b>Add urls</b></FormLabel>
              	<Textarea
									id="urls"
									// size="lg"
									h={50}
									placeholder="Enter comma (,) separated URLs"
              	/>
							</Box>

							<Box>
								<FormLabel htmlFor="file" mt='3'><b>Upload Excel File To Add Links</b></FormLabel>
								<input id="fileSelect" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xls, .xlsx, .csv," onChange={(e) => setLinksFile(e.target.files[0])}/>
							</Box>

							{ allCities &&
								<Box>
									<FormControl isRequired>
										<FormLabel htmlFor="frequency"><b>Select City</b></FormLabel>
										<Select placeholder="Select City" value={selectedCityId} onChange={(e) => {setSelectedCityId(e.target.value)}}>
											{	allCities.cities.map((i, index) => 
													<option key={index} value={i.id}>{i.name}</option>
												)}
										</Select>
									</FormControl>
									<small>(New links will be added to this city)</small>
								</Box>
							}

							<Box>
                <FormControl isRequired>
                  <FormLabel htmlFor="frequency"><b>Select Frequency</b></FormLabel>
                  <Select placeholder="Select Frequency" value={frequency} onChange={(e) => {setFrequency(e.target.value)}}>
                    {frequencyData.map((i, index) => 
                        <option key={index} value={i.value}>{i.key}</option>
                      )}
                  </Select>
                </FormControl>
              </Box>

              <Box>
                <FormControl isRequired>
                  <FormLabel htmlFor="scheduleDate"><b>Schedule Date</b></FormLabel>
                  <DatePicker
                    id="scheduleDate"
                    selected={scheduleDate}
                    onChange={(date) => setScheduleDate(date)}
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                    showTimeInput
                  />
                </FormControl>
              </Box>

            </Stack>
          <Button colorScheme="blue" onClick={saveScrape} mt={4}>
              Save Scrape
            </Button>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
    </>
  )
}
