import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerHeader,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button,
  Textarea,
  Box,
  useToast,
  Select,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import {
  GET_SCRAPE_ENTRIES,
  ALL_CITIES,
  Edit_SCRAPE_LINKS,
  DELETE_SCRAPE_ENTRY,
} from '../api';
import { FaTrash } from 'react-icons/fa';

export default function ScrapeEntryEdit({
  scrape,
  showScrapeEntryEdit,
  setShowScrapeEntryEdit,
}) {
  const toast = useToast();

  const [linksToAdd, setLinksToAdd] = useState('');
  const [linksArray, setLinksArray] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [linksFile, setLinksFile] = useState(null);

  const [editScrapeLinks] = useMutation(Edit_SCRAPE_LINKS, {
    context: { hasUpload: true },
    refetchQueries: [GET_SCRAPE_ENTRIES],
    onCompleted: (data) => {
      if (data.editScrapeLinks.errors.length > 0) {
        toast({
          title: data.editScrapeLinks.message,
          status: 'error',
          duration: 4000,
        });
      } else {
        toast({
          title: data.editScrapeLinks.message,
          status: 'success',
          duration: 4000,
        });
        resetInput();
      }
    },
  });

  useQuery(ALL_CITIES, {
    onCompleted: (data) => {
      setCities(data.cities.map((e) => {
          return {
            name: e.name,
            value: e.id,
          };
        }));
			},
  });

  const { data: scrapeEntriesData } = useQuery(
    GET_SCRAPE_ENTRIES,
    {
      variables: {
        scrapeId: Number(scrape.id),
      },
    }
  );

  const [deleteScrapeEntry] = useMutation(
    DELETE_SCRAPE_ENTRY,
    {
      refetchQueries: [GET_SCRAPE_ENTRIES],
    }
  );

  function isValidUrl(str) {
    var regex =
      /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    if (!regex.test(str)) {
      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    var linksArray = linksToAdd
      .split(' ')
      .join('')
      .split(',')
      .filter((l) => l.length > 5 && isValidUrl(l));

    if (linksArray.length > 0) {
      toast.closeAll();
      toast({
        title: `${linksArray.length} valid URLs found`,
        position: 'top-right',
        isClosable: true,
      });
      setLinksArray(linksArray);
    }
  }, [linksToAdd]);

  function resetInput() {
    setLinksToAdd('');
    setLinksArray([]);
  }

  function handleSaveLinks() {
    if ((linksArray.length > 0 || linksFile) && selectedCity) {
      editScrapeLinks({
        variables: {
          scrapeId: Number(scrape.id),
          cityId: Number(selectedCity),
          addLinksArray: linksArray,
          linksFile: linksFile,
        },
      });
    } else {
      toast({
        title: 'No URLs found Or No City Selected',
        position: 'top-right',
        status: 'error',
        isClosable: true,
      });
    }
  }

  function handleDelete(scrapeEntryId) {
    deleteScrapeEntry({
      variables: {
        scrapeId: Number(scrape.id),
        scrapeEntryId: Number(scrapeEntryId),
      },
    });
  }

  return (
    <Drawer
      isOpen={showScrapeEntryEdit}
      placement='right'
      onClose={() => setShowScrapeEntryEdit(false)}
      size='xl'
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton onClick={() => setShowScrapeEntryEdit(false)} />
        <DrawerHeader>
          {scrape.name}{' '}
          <small>({scrape.scrapeEntriesCount} Scrape Entries Found)</small>
        </DrawerHeader>

        <DrawerBody>
          {cities && (
            <Box mb='5' p='5'>
              <Textarea
                placeholder='Enter comma(,) separated URLs & Select city (In case if any of these urls are not present in database, they will be added under selected city)'
                value={linksToAdd}
                onChange={(e) => setLinksToAdd(e.target.value)}
              />
              <Flex>
                <Box mt={4}>
                  {/* <Label htmlFor="file" mt='3'><b>Upload Excel File To Add Links</b></Label> */}
                  <input
                    id='fileSelect'
                    type='file'
                    placeHolder='hello'
                    accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xls, .xlsx, .csv,'
                    onChange={(e) => setLinksFile(e.target.files[0])}
                  />
                </Box>
                <Spacer />
                <Box mt={4} mr={4}>
                  <Select
                    placeholder='Select City'
                    size='md'
                    onChange={(e) => setSelectedCity(e.target.value)}
                  >
                    {cities.map((c) => {
                      return <option value={c.value}>{c.name}</option>;
                    })}
                  </Select>
                </Box>
                <Box mt={4}>
                  <Button
                    colorScheme='teal'
                    type='submit'
                    onClick={handleSaveLinks}
                  >
                    Save Links
                  </Button>
                </Box>
              </Flex>
            </Box>
          )}
          {scrapeEntriesData?.scrapeEntries?.length > 0 && (
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>URL</Th>
                    <Th>Delete</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {scrapeEntriesData.scrapeEntries.length > 0 &&
                    scrapeEntriesData.scrapeEntries.map((e) => {
                      return (
                        <Tr>
                          <Td
                            onClick={() =>
                              navigator.clipboard.writeText(e.link.url)
                            }
                          >
                            {e.link.url}
                          </Td>
                          <Td>
                            <Button
                              size='sm'
                              colorScheme='red'
                              onClick={() => handleDelete(e.id)}
                            >
                              <FaTrash />
                            </Button>
                          </Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
