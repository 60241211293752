import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import "react-datepicker/dist/react-datepicker.css";
import { SCRAPEUPDATE, SCRAPES } from "../../api";
import { FaEdit } from "react-icons/fa";

import {
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Box,
  FormControl,
  FormLabel,
  Button,
  Stack,
  Input,
  Select,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";

export default function EditScrapePopup2({ scrapeName, scrapeId, frequency, scheduledDate, refetchScrapes}) {
  const toast = useToast();
  const [currentScrapeFrq, setCurrentScrapeFrq] = useState(frequency);
  const [currentScrapeSchedule, setCurrentScrapeSchedule] = useState(scheduledDate);
	const [scrpName, setScrpName] = useState(scrapeName);

  const [scrapeUpdate] = useMutation(SCRAPEUPDATE, {
    refetchQueries: [SCRAPES],
    onCompleted: (data) => {
			refetchScrapes({})
			console.log(data, "scrape update data")
			if (data.scrapeUpdate.errors?.length > 0) {
				data.scrapeUpdate.errors.map(e => {
					return (
						toast({
							title: e,
							status: "error",
							duration: 5000,
							isClosable: true,
						})
					)
				})

			} else {
				toast({
					title: data.scrapeUpdate.message,
					status: "success",
					duration: 5000,
					isClosable: true,
				})
			}
    },
  });

  const saveScrape = () => {
   scrapeUpdate({
        variables: {
          scrapeId: Number(scrapeId),
					name: scrpName,
          frequency: String(currentScrapeFrq),
          scheduledAt: currentScrapeSchedule,
        },
      });
      document.getElementsByClassName("chakra-popover__close-btn")[0].click();
  };

  let frequencyData = [
    {
      key: "Every 6 Hours",
      value: "daily4",
    },
    {
      key: "Every 8 Hours",
      value: "daily3",
    },
    {
      key: "Every 12 Hours",
      value: "daily2",
    },
    {
      key: "Daily",
      value: "daily",
    },
    {
      key: "Weekly",
      value: "weekly",
    },
    {
      key: "Monthly",
      value: "monthly",
    }];

  return (
    <>
      <Box>
        <Popover placement="bottom-end" isLazy>
          <PopoverTrigger>
            <Button id="editScrapeBtn" size='sm' variant='ghost' ml={1}><FaEdit/>&nbsp;&nbsp; Edit Scrape</Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverHeader fontWeight="semibold">Edit Scrape</PopoverHeader>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody>
              <Stack spacing="24px">
                <Box>
                  <Input
										size='lg'
										value={scrpName}
										onChange={(e) => setScrpName(e.target.value)}
									/>
                </Box>

                <Box>
                  <FormControl isRequired>
                    <FormLabel htmlFor="frequency">Select Frequency</FormLabel>
                    {currentScrapeFrq ? 
                       <Select id="frequency3" value={currentScrapeFrq} onChange={(event) => setCurrentScrapeFrq(event.target.value)} placeholder="Select Frequency">
                        {frequencyData.map((i, index) => (
                          <option
                            key={index}
                            value={i.value}
                          >
                            {i.key}
                          </option>
                        ))}
                      </Select> : null
                    }
                  </FormControl>
                </Box>

                <Box>
                  <FormControl isRequired>
                    <FormLabel htmlFor="schedule_Date">Schedule Date</FormLabel>
                    {currentScrapeSchedule ? 
                    <DatePicker
                      id="schedule_Date"
                      selected={new Date(currentScrapeSchedule)}
                      onChange={(date) => {setCurrentScrapeSchedule(date)}}
                      dateFormat="yyyy-MM-dd HH:mm:ss"
                      showTimeInput
                    /> : null }
                  </FormControl>
                </Box>
																
              </Stack>
              <Button colorScheme="blue" onClick={saveScrape} mt={4}>
                Save Scrape
              </Button>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
    </>
  );
}
