import { gql } from '@apollo/client';

// export const SCRAPE_PROGRESS = gql`
//   subscription scrapeProgress($scrapeId: ID!) {
//     scrapeProgress(scrapeId: $scrapeId) {
//       progress
//     }
//   }
// `;

export const SCRAPE_PROGRESS = gql`
subscription scrapeProgress{
  scrapeProgress {
    scrapeHistory {
			canceledScrapeEntriesCount
			completedScrapeEntriesCount
			id
			scrapeId
			name
			scrapeEntriesCount
			startedAt
			status
			runTime
			avgRunTime
    }
  }
}
`;
