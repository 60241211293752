import React, { useEffect, useState, useMemo } from 'react';
import { ALL_CITIES, LINKS, LINKDISCARD, LINKUNDISCARD, UPDATE_FPIMG_FETCHING, SCRAPE_LINK } from '../api';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import {
  useToast,
  Box,
  Heading,
  Button,
  FormControl,
  FormLabel,
  Switch,
  Flex,
	Tooltip
} from '@chakra-ui/react';
import { FaTrashAlt, FaUndoAlt, FaSpider, FaCircle } from 'react-icons/fa';
import SprapeTable from './table/SprapeTable';
import AddLinkPopover from './ui/AddLinkPopover';
import moment from "moment";

export default function LinksManager() {
  const [links, setLinks] = useState([]);
  const [cities, setCities] = useState([])
  const [showDiscarded, setShowDiscarded] = useState(false);
  const [currentCity, setCurrentCity] = useState(0);

  const toast = useToast();

  const { data: citiesData, loading } = useQuery(ALL_CITIES, {
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      setCities(data.cities)
    },
  });

  const handleCityChange = (e) => {
    setCurrentCity(e.target.value);
  };

  const [linksQuery, { data: linksData }] =
    useLazyQuery(LINKS);

  const [linkDiscard] = useMutation(LINKDISCARD, {
    refetchQueries: [LINKS],
    onCompleted: (data) => {
      toast({
        title: data.linkDiscard.message,
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    },
  });

  const [linkUndiscard] = useMutation(
    LINKUNDISCARD,
    {
      refetchQueries: [LINKS],
      onCompleted: (data) => {
        toast({
          title: data.linkUndiscard.message,
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      },
    }
  );

	const [updateFpimgFetching] = useMutation(UPDATE_FPIMG_FETCHING, {
		refetchQueries: [LINKS],
		onCompleted: (data) => {
			toast({
				title: data.updateFpimgFetching.message,
				status: 'success',
				duration: 4000,
				isClosable: true,
			})
			
		}
	})

	const [scrapeLink] = useMutation(SCRAPE_LINK, {
		onCompleted: (data) => {
			if (data.scrapeLink.errors.length === 0){
				toast({
					title: data.scrapeLink.message,
					status: 'success',
					duration: 4000,
					isClosable: true,
				})
			} else {	
				data.scrapeLink.errors.map(e => {
					toast({
						title: e,
						status: 'error',
						duration: 4000,
						isClosable: true,
					})
				})
			}
		}
	})

  useEffect(() => {
    if (linksData) {
			let filteredLinks = []
      filteredLinks = showDiscarded ? linksData.links.edges : linksData.links.edges.filter((l) => l.node.discardedAt === null);
			filteredLinks = filteredLinks.map(l => {
				return ({
					...l,
					status: l.node.success ? "success" : l.node.success === null ? "na" : "fail",
					fetchFPImages: l.node.fetchFloorplanImages ? "true" : "false"
				})
			})
			console.log(filteredLinks, "filteredLinks")
			setLinks(filteredLinks)
		}
  }, [linksData, showDiscarded]);

  const linkUpdate = (row, value) => {
    var linkId = row.original.node.id;
    if (value) {
      linkUndiscard({
        variables: { id: Number(linkId) },
      });
    } else {
      linkDiscard({
        variables: { id: Number(linkId) },
      });
    }
  };

	const toggleFPImageFetching = (row, value) => {
		updateFpimgFetching({
			variables: {
				id: Number(row.original.node.id),
				fetchFloorplanImages: !value
			}
		})
	}

  const COLUMNS = [
    {
      Header: 'URL',
      accessor: 'node.url',
			Cell: ({row, value}) => {
				return <div onClick={() => navigator.clipboard.writeText(value)}>{value}</div>
			}
    },
    {
      Header: 'Status',
      accessor: 'status',
			Cell: ({row, value}) => {
				return value === "success" ? <FaCircle color='green'/> : value === 'na' ? <FaCircle color='orange'/> : <FaCircle color='red'/>  
			}
    },
    {
      Header: 'Name',
      accessor: 'node.name',
    },
    {
      Header: 'City',
      accessor: 'node.city.name',
    },
    {
      Header: 'Used In Scrape',
      accessor: 'node.partOfScrape',
    },
		{
			Header: "Last Scraped On",
			accessor: "node.lastScraped",
			Cell: ({row, value}) => {
				return (
					<Tooltip
						hasArrow
						label={value ? moment(value).format("DD MMMM YYYY, h:mm a") : "-"}
					>
						{value ? moment(value).fromNow() : "-"}
					</Tooltip>
				);
			},
		},
    {
      Header: 'Spark ID',
      accessor: 'node.sId',
    },
    {
      Header: 'Fetch Floorplan Images?',
      accessor: 'fetchFPImages',
			Cell: ({row, value}) => {
				return (
					<Switch 
						colorScheme='teal' 
						size='lg' 
						isChecked={JSON.parse(value)} 
						onChange={() => {
							toggleFPImageFetching(row, JSON.parse(value));
						}
					}/>
				)
			}
    },
		{
			Header: 'Scrape',
			accessor: 'node.id',
			Cell: ({row, value}) => {
				return (
					<Button
						size='sm'
						colorScheme='green'
						onClick={() => {
							scrapeLink({
								variables: {id: parseInt(value)}
							})
						}}
					>
						<FaSpider />
					</Button>
				)
			}
		},
    {
      Header: 'Action',
      accessor: 'node.discardedAt',
      Cell: ({ row, value }) => {
        return value ? (
          <Button
            id={row.original.node.id}
            onClick={() => linkUpdate(row, value)}
            colorScheme='green'
            size='sm'
          >
            <FaUndoAlt />
          </Button>
        ) : (
          <Button
            id={row.original.node.id}
            onClick={() => linkUpdate(row, value)}
            colorScheme='red'
            size='sm'
          >
            <FaTrashAlt />
          </Button>
        );
      },
    },
  ];

  const columns = useMemo(() => COLUMNS, []);

  useEffect(() => {
    linksQuery({
      variables: Number(currentCity) > 0 ? { cityId: Number(currentCity) } : {},
    });
  }, [currentCity]);

  return (
    <>
      <Flex mb={2} alignItems='center' justifyContent='space-between'>
        <Box>
          <Heading as='h3'>Links</Heading>
        </Box>
        <Box>
          <FormControl display='flex' alignItems='center'>
            <FormLabel htmlFor='email-alerts' mb='0'>
              Show Discarded?
            </FormLabel>
            <Switch
              id='showDiscarded'
							size='lg'
              onChange={() => setShowDiscarded(!showDiscarded)}
              isChecked={showDiscarded}
            />
          </FormControl>
        </Box>
      </Flex>
      {loading && links.length > 0 ? 'Loading...' : ''}
      <Box>
        <SprapeTable
          data={links}
          columns={columns}
          isPagination={true}
          isSelectRows={true}
          createForm={
            <AddLinkPopover
              currentCity={currentCity}
              cities={cities}
            />
          }
          cityLinksList={true}
          citiesData={citiesData}
          handleCityChange={handleCityChange}
        />
      </Box>
    </>
  );
}
