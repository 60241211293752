import React, { useEffect, useState, useMemo } from "react";
import { SCRAPES, SCRAPERUN, SCRAPECANCEL, SCRAPE_PROGRESS, ALL_SCRAPES, SCRAPE_HISTORY, TOGGLE_SCRAPE_DISCARD } from "../api";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import CurrentScrape from "./CurrentScrape";
import { Spacer, Tooltip, useToast } from "@chakra-ui/react";
import { FaPlay, FaTimesCircle, FaBars, FaStream, FaCog, FaTrash, FaUndo } from "react-icons/fa";
import {
	Box,
	Heading,
	Button,
	Menu,
	MenuButton,
	MenuList,
	Tag,
	MenuItem,
	FormControl,
	FormLabel,
	Switch,
	Flex
} from "@chakra-ui/react";
import SprapeTable from "./table/SprapeTable";
import moment from "moment";
import EditScrapePopup2 from "./ui/EditScrapePopup2";
import ScrapeHistory from "./ScrapeHistory";
import ScrapeEntryEdit from "./ScrapeEntryEdit"
import CreateScrapePopup from "./ui/CreateScrapePopup";
export default function ScrapesManager() {

	const [scrapes, setScrapes] = useState([]);
	const toast = useToast();
	const [runningScrape, setRunningScrape] = useState(null);
	const [isShowScrapeHistory, setIsShowScrapeHistory] = useState(false);
	const [showScrapeHistory, setShowScrapeHistory] = useState(null);
	const [showScrapeEntryEdit, setShowScrapeEntryEdit] = useState(false);
	const [editScrape, setEditScrape] = useState(null);
	const [showDiscardedScrape, setShowDiscardedScrape] = useState(false);

	useQuery(SCRAPES, {
		fetchPolicy: "network-only",
		variables: { status: "inprogress" },
		onCompleted: (data) => {
			if (data.scrapes.edges[0].node.status === "inprogress") {
				setRunningScrape(data.scrapes.edges[0].node);
			}
		}
	});

	useSubscription(SCRAPE_PROGRESS, {
		onSubscriptionData: ({ subscriptionData: { data } }) => {
			if (Object.keys(data).length !== 0 && data.scrapeProgress.scrapeHistory.status === "inprogress"){
				if (runningScrape === null) refetch({});
				setRunningScrape(data.scrapeProgress.scrapeHistory);
			} else if (Object.keys(data).length !== 0 && data.scrapeProgress.scrapeHistory.status === "completed"){
				refetchAllScrape();
			} 
		}
	});

	const { data: allScrapesData, refetch } = useQuery(ALL_SCRAPES);

	useQuery(SCRAPE_HISTORY, {
		variables: {
			status: "inprogress"
		},
		onCompleted: (data) => {
			setRunningScrape(data.scrapeHistory);
		}
	});

	const [scrapeRun] = useMutation(SCRAPERUN, {
		refetchQueries: [ALL_SCRAPES],
		onCompleted: (data) => {
			if (Object.keys(data).length !== 0 && data.scrapeRun.scrape.status === "inprogress"){
				setRunningScrape(data.scrapeRun.scrape);
			}
			if (data.scrapeRun?.errors?.length > 0){
				toast({
					title: data.scrapeRun.message,
					status: "error",
					duration: 4000,
					isClosable: true,
				});
			} else {
				toast({
					title: data.scrapeRun.message,
					status: "success",
					duration: 4000,
					isClosable: true,
				});
			}
		},
	});

	const [scrapeCancel] = useMutation(SCRAPECANCEL, {
		refetchQueries: [ALL_SCRAPES],
		onCompleted: (data) => {
			toast({
				title: data.scrapeCancel.message,
				status: "success",
				duration: 4000,
				isClosable: true,
			});
		},
	});

	const [scrapeDiscard] = useMutation(TOGGLE_SCRAPE_DISCARD, {
		refetchQueries: [ALL_SCRAPES]
	});

	useEffect(() => {
		allScrapesData && setScrapes(allScrapesData.allScrape.edges.filter(e => {
			if (showDiscardedScrape) {
				return true;
			} else if (e.node.discardedAt === null) {
				return true;
			} else return false;
		}));
	}, [allScrapesData, showDiscardedScrape]);

	const runScrape = (row) => {
		if (row.node.status === "canceled") {
			scrapeRun({
				variables: { scrapeId: Number(row.node.id), runMode: "resume" },
			});
		} else {
			scrapeRun({
				variables: { scrapeId: Number(row.node.id) },
			});
		}
	};

	const cancelScrape = (row) => {
		scrapeCancel({
			variables: { scrapeId: Number(row.node.id) },
		});
	};

	function refetchAllScrape(){
		refetch({});
		setRunningScrape(null);
	}

	function toggleScrapeDiscard(scrape){
		scrapeDiscard({
			variables: {
				scrapeId: Number(scrape.id),
				discard: !scrape.discardedAt
			}
		})
	}

	const frequencyFilter = (val) => {
		var freq;
		switch (val) {
			case "daily":
				freq = "Daily";
				break;
			case "daily2":
				freq = "Every 12 Hours";
				break;
			case "daily3":
				freq = "Every 8 Hours";
				break;
			case "daily4":
				freq = "Every 6 Hours";
				break;
			case "weekly":
				freq = "Weekly";
				break;
			case "monthly":
				freq = "Monthly";
				break;

			default:
				freq = "Invalid frequency";
				break;
		}
		return freq;
	};

	function getStyledStatus(status) {
		if (status === "completed"){
			return <Tag variant='solid' colorScheme='green' borderRadius='full'>Completed</Tag> 
		} else if (status === "canceled"){
			return <Tag variant='solid' colorScheme='red' borderRadius='full'>Cancelled</Tag> 
		} else if (status === "inprogress") {
			return <Tag variant='solid' bgGradient='linear(to-r, orange.500, orange.400)' borderRadius='full'>In Progress</Tag> 
		} else {
			return null
		}
	}

	const columnsForUpcoming = useMemo(
		() => [

			{
				Header: "Name",
				accessor: "node.name",
			},
			{
				Header: "Scrape Entries",
				accessor: "node.scrapeEntriesCount",
			},
			{
				Header: "Frequency",
				accessor: (row) => {
					return frequencyFilter(row.node.frequency);
				},
			},
			{
				Header: "Schedule At",
				accessor: (row) => {
					return (
						<Tooltip
							hasArrow
							label={moment(row.node.scheduledAt).format("DD MMMM YYYY, h:mm a")}
						>
							{moment(row.node.scheduledAt).fromNow()}
						</Tooltip>
					);
				},
			},
			{
				Header: "Last Executed On",
				accessor: (row) => {
					return (
						<Tooltip
							hasArrow
							label={row.node.endedAt ? moment(row.node.endedAt).format("DD MMMM YYYY, h:mm a") : "-"}
						>
							{row.node.endedAt ? moment(row.node.endedAt).fromNow() : "-"}
						</Tooltip>
					);
				},
			},
			{
				Header: "Avg Run Time",
				accessor: "node.avgRunTime",
				Cell: ({row, value}) => {
					if (value !== null){
						var hours = Math.floor(value/3600)
						var minutes = Math.floor(value/60) - hours*60
						var seconds = value - minutes*60 - hours*3600
						if (hours > 0) return `${hours}h ${minutes}m ${seconds}s`
						else return `${minutes}m ${seconds}s`
					} else return ""
				}
			},
			{
				Header: "Status",
				accessor: (row) => {
					return getStyledStatus(row.node.status)
				}
			},
			{
				Header: "Action",
				accessor: (row) => {
					return (
						<Menu>
							<MenuButton as={Button} colorScheme={row.node.discardedAt ? "red" : "blue"}>
								<FaBars />
							</MenuButton>
							<MenuList>
								{
									!row.node.discardedAt && 
									<MenuItem onClick={() => runScrape(row)}>
										<FaPlay />&nbsp;&nbsp; Run Scrape 
									</MenuItem>
								}
								<MenuItem onClick={() => cancelScrape(row)}>
									<FaTimesCircle />&nbsp;&nbsp; Cancel Scrape 
								</MenuItem>
								<MenuItem onClick={() => handleShowScrapeHistory(row.node)}>
									<FaStream/>&nbsp;&nbsp; Show Scrape History
								</MenuItem>
								<MenuItem onClick={() => handleLinksEdit(row.node)}>
									<FaCog/>&nbsp;&nbsp; Manage Links
								</MenuItem>
								<MenuItem onClick={() => toggleScrapeDiscard(row.node)}>
									{row.node.discardedAt ? <><FaUndo/>&nbsp;&nbsp; Undiscard Scrape</> : <><FaTrash/>&nbsp;&nbsp; Discard Scrape</>}
								</MenuItem>
								<EditScrapePopup2
									scrapeId={row.node.id}
									scrapeName={row.node.name}
									frequency={row.node.frequency}
									scheduledDate={row.node.scheduledAt}
									refetchScrapes={refetch}
								/>
							</MenuList>
						</Menu>
					);
				},
			},
		],
		[]
	);

	function handleShowScrapeHistory(scrape){
		setIsShowScrapeHistory(true);
		setShowScrapeHistory(scrape);
	}

	function handleLinksEdit(scrape){
		setShowScrapeEntryEdit(true);
		setEditScrape(scrape);
	}

	return (
		<>
			{
				isShowScrapeHistory &&
				<ScrapeHistory 
					scrape={showScrapeHistory}
					isShowScrapeHistory={isShowScrapeHistory}
					setIsShowScrapeHistory={setIsShowScrapeHistory}
					getStyledStatus={getStyledStatus}
					// avgScrapeRunTime={}
				/>
			}
			{
				showScrapeEntryEdit &&
				<ScrapeEntryEdit 
					scrape={editScrape}
					showScrapeEntryEdit={showScrapeEntryEdit}
					setShowScrapeEntryEdit={setShowScrapeEntryEdit}
				/>
			}
			<Box>
					<Flex>
						<Box><Heading as="h3">Scrapes</Heading></Box><Spacer/>
						<Box>
							<FormControl display='flex' alignItems='center'>
								<FormLabel htmlFor='email-alerts' mb='0'>
									Show Discarded?
								</FormLabel>
								<Switch
									id='showDiscarded'
									onChange={() => setShowDiscardedScrape(!showDiscardedScrape)}
									isChecked={showDiscardedScrape}
									size='lg'
								/>
							</FormControl>
						</Box>
					</Flex>
				{runningScrape && 
					<CurrentScrape scrape={runningScrape} refetchScrapes={refetchAllScrape}/>
				}
				<Box mt='2'>
					<SprapeTable
						data={scrapes}
						columns={columnsForUpcoming}
						isPagination={true}
						isSelectRows={false}
						createScrape={<CreateScrapePopup/>}
					/>
				</Box>
			</Box>
		</>
	);
}
