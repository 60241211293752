import React, {useEffect, useState} from "react";
import { useLazyQuery } from "@apollo/client";

import {
	Drawer,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	DrawerBody,
	DrawerHeader,
	TableContainer,
	Table,
	Thead,
	Tr,
	Th,
	Tbody,
	Td,
	Button,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverCloseButton,
	PopoverBody,
	Text
} from "@chakra-ui/react";
import { useDisclosure } from '@chakra-ui/react'
import moment from "moment";
import { SCRAPE_HISTORY } from "../api";

export default function ScrapeHistory({scrape, isShowScrapeHistory, setIsShowScrapeHistory, getStyledStatus}) {
	const [showScrapeHistory, setShowScrapeHistory] = useState(null);
  const { onClose } = useDisclosure();
	const [showScrapeEntries, setShowScrapeEntries] = useState(false);
	const [scrapeEntriesStatus, setScrapeEntriesStatus] = useState("all");

	const [scrapeHistoryQuery, {data: scrapeHistoryData}] = useLazyQuery(SCRAPE_HISTORY);

	useEffect(() => {
		if (scrapeHistoryData) {
			setShowScrapeEntries(true);
			setShowScrapeHistory(scrapeHistoryData.scrapeHistory);
		}
	}, [scrapeHistoryData])

	function handleScrapeEntryShow(scrapeHistory, status="all"){
		setShowScrapeEntries(true);
		setScrapeEntriesStatus(status);
		scrapeHistoryQuery({
			variables: {
				id: Number(scrapeHistory.id)
			}
		})
	}

	function getRunTime(value){
		if (value !== null && value > 0){
			var hours = Math.floor(value/3600)
			var minutes = Math.floor(value/60) - hours*60
			var seconds = value - minutes*60 - hours*3600
			if (hours > 0) return `${hours}h ${minutes}m ${seconds}s`
			else return `${minutes}m ${seconds}s`
		} else return ""
	}

  return (
    <>
			{
				showScrapeEntries && 
				<ShowScrapeEntries
					scrapeHistory={showScrapeHistory}
					showScrapeEntries={showScrapeEntries}
					setShowScrapeEntries={setShowScrapeEntries}
					getStyledStatus={getStyledStatus}
					scrapeEntriesStatus={scrapeEntriesStatus}
				/>
			}
      <Drawer onClose={onClose} isOpen={isShowScrapeHistory} size='full'>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton onClick={() => setIsShowScrapeHistory(false)}/>
          <DrawerHeader>
						{scrape.name} <small>(Executed {scrape.scrapeHistories.length} Times)</small>
					</DrawerHeader>
          <DrawerBody>
						<TableContainer p='5'>
							<Table size='sm'>
								<Thead>
									<Tr>
										<Th>Started At</Th>
										<Th>Ended At</Th>
										<Th>Status</Th>
										<Th>Run Time</Th>
										<Th>Scrape Result</Th>
										<Th>Completed Scrape Entries</Th>
										<Th>Cancelled Scrape Entries</Th>
										<Th>Total Scrape Entries</Th>
									</Tr>
								</Thead>
								<Tbody>
									{ scrape.scrapeHistories.length > 0 ?
										scrape.scrapeHistories.map(sh => {
											return (
												<Tr key={sh.id}>
													<Td>
														{sh.startedAt ? moment(sh.startedAt).format("DD MMMM YY, h:mm a") : "-"}
													</Td>
													<Td>
														{sh.endedAt ? moment(sh.endedAt).format("DD MMMM YY, h:mm a") : "-"}
													</Td>
													<Td>{getStyledStatus(sh.status)}</Td>
													<Td>
														{ getRunTime(sh.runTime)}
													</Td>
													<Td>
														<Popover closeOnBlur={false} placement='left'>
															<PopoverTrigger>
																<Button colorScheme='blue' variant='outline'>Show Result</Button>
															</PopoverTrigger>
															<PopoverContent>
															<PopoverHeader>Raw Hash</PopoverHeader>
																<PopoverCloseButton />
																<PopoverBody>
																	<div style={{overflowY: "scroll", height: "300px", width: "300px", whiteSpace: "pre-wrap"}}>
																		{ JSON.stringify(sh.scrapeResult) }
																	</div>
																</PopoverBody>
															</PopoverContent>
														</Popover>
													</Td>
													<Td>
														{ sh.completedScrapeEntriesCount > 0 &&
															<Button
																onClick={() => handleScrapeEntryShow(sh, "completed")}
																colorScheme='green' size='md'
																variant='outline'
															>
																{sh.completedScrapeEntriesCount}
															</Button>
														}
													</Td>
													<Td>
														{ sh.canceledScrapeEntriesCount > 0 &&
															<Button
																onClick={() => handleScrapeEntryShow(sh, "cancelled")}
																colorScheme='red' size='md'
																variant='outline'
															>
																{sh.canceledScrapeEntriesCount}
															</Button>
														}
													</Td>
													{/* <Td>
														{ (sh.scrapeEntriesCount - sh.canceledScrapeEntriesCount - sh.completedScrapeEntriesCount) > 0 &&
															<Button
																onClick={() => handleScrapeEntryShow(sh, "inprogress")}
																colorScheme='orange' size='md'
																variant='outline'
															>
																{sh.scrapeEntriesCount - sh.canceledScrapeEntriesCount - sh.completedScrapeEntriesCount}
															</Button>
														}
													</Td> */}
													<Td>
														{ sh.scrapeEntriesCount > 0 &&
															<Button
																onClick={() => handleScrapeEntryShow(sh)}
																colorScheme='blue' size='md'
																variant='outline'
															>
																{sh.scrapeEntriesCount}
															</Button>
														}
													</Td>
												</Tr>
											)
										}) : <Text p='5'>No Scrape History Found</Text>
									}
								</Tbody>
							</Table>
						</TableContainer>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

function ShowScrapeEntries({scrapeHistory, showScrapeEntries, setShowScrapeEntries, getStyledStatus, scrapeEntriesStatus}) {
	
	function filteredScrapeEntries(scrapeHistory){
		if (scrapeEntriesStatus === "all"){
			return scrapeHistory.scrapeEntryHistories;
		} else if (scrapeEntriesStatus === "completed"){
			return scrapeHistory.scrapeEntryHistories.filter(se => se.status === "completed");
		} else if (scrapeEntriesStatus === "cancelled"){
			return scrapeHistory.scrapeEntryHistories.filter(se => se.status === "canceled");
		} else if (scrapeEntriesStatus === "inprogress"){
			return scrapeHistory.scrapeEntryHistories.filter(se => se.status === "inprogress");
		}
	}
	
	return (
		<Drawer onClose={() => setShowScrapeEntries(false)} isOpen={showScrapeEntries} size='xl'>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton onClick={() => setShowScrapeEntries(false)} />
				<DrawerHeader borderBottomWidth='1px'>Scrape Entries</DrawerHeader>
				<DrawerBody>
					{ scrapeHistory?.scrapeEntryHistories.length > 0 ?
						<TableContainer>
							<Table size='sm'>
							<Thead>
									<Tr>
										<Th>URL</Th>
										<Th>Status</Th>
										<Th>Raw Hash</Th>
									</Tr>
								</Thead>
								<Tbody>
									{
										filteredScrapeEntries(scrapeHistory).map(seh => {
											return (
													<Tr>
														<Td>{seh.scrapeEntry.link.url}</Td>
														<Td>{getStyledStatus(seh.status)}</Td>
														<Td>
															<Popover closeOnBlur={false} placement='left'>
																<PopoverTrigger>
																	<Button colorScheme='blue' variant='outline'>Show Hash</Button>
																</PopoverTrigger>
																<PopoverContent>
																<PopoverHeader>Raw Hash</PopoverHeader>
																	<PopoverCloseButton />
																	<PopoverBody>
																		<div style={{overflowY: "scroll", height: "300px", width: "300px", whiteSpace: "pre-wrap"}}>
																			{seh.rawHash}
																		</div>
																	</PopoverBody>
																</PopoverContent>
															</Popover>
														</Td>
													</Tr>
												)
											})
									}
								</Tbody>

							</Table>
						</TableContainer> : <Text p='5'>Loading Scrape Entries......</Text>
					}

				</DrawerBody>
			</DrawerContent>
		</Drawer>
	)
}