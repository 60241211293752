import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AllRoutes } from '../config';
import { Box } from '@chakra-ui/react';

import Nav from './ui/Nav';
import AuthUser from '../AuthUser';

export default function Home() {
	const [isAuthorized, setIsAuthorized] = useState(localStorage.getItem("auth_sprape_user") === "sprape_admin" ? true : false); 

  return (
    <>
			<AuthUser.Provider value={{isAuthorized, setIsAuthorized}}>
				<BrowserRouter>
					{ isAuthorized && <Nav /> }
					<Box px={5} py={3}>
						<AllRoutes />
					</Box>
				</BrowserRouter>
			</AuthUser.Provider>
    </>
  );
}
