import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import "react-datepicker/dist/react-datepicker.css";
import { SCRAPEUPDATE, SCRAPES, ALL_SCRAPES } from "../../api";
import {
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Box,
  FormControl,
  FormLabel,
  Button,
  Stack,
  Tag,
  Select,
  Textarea,
} from "@chakra-ui/react";

export default function EditScrapePopup({ selectedLinks }) {
  const toast = useToast();

  const [allScrapes, setAllScrapes] = useState(null);

  const { data: allScrapesData, loading: scrapesLoading } = useQuery(
    ALL_SCRAPES);

  const [scrapeUpdate] = useMutation(SCRAPEUPDATE, {
    refetchQueries: [SCRAPES],
    onCompleted: (data) => {
      toast({
        title: data.scrapeUpdate.message,
        status: "info",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const saveScrape = () => {
    let scrape_id = document.getElementById("scrape_id").value;
    let removeScrapeEntryIds = document.getElementById("removeScrapeEntryIds").value.split(" ").join("").split(",").map((i) => parseInt(i));
    let enteredLinks = selectedLinks.map((i) => parseInt(i));
    
    if (scrape_id !== "") {
      scrapeUpdate({
        variables: {
          scrapeId: Number(scrape_id),
          addLinkIds: enteredLinks,
          removeScrapeEntryIds: removeScrapeEntryIds,
        },
      });
      document.getElementsByClassName("chakra-popover__close-btn")[0].click();
    } else {
      toast({
        title: "Enter Scrape Name",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

	useEffect(() => {
		if (!scrapesLoading && allScrapesData?.allScrape?.edges.length > 0){
			let scrapes = allScrapesData.allScrape.edges.filter(s => !s.node.discardedAt).map(e => {
					return (
						{
							name: e.node.name,
							id: e.node.id
						}
					)
			})
			setAllScrapes(scrapes);
		}
	}, [allScrapesData]);

  return (
    <>
      <Box>
        <Popover placement="bottom-end" isLazy>
          <PopoverTrigger>
            <Button colorScheme="blue">Edit Scrape</Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverHeader fontWeight="semibold">Edit Scrape</PopoverHeader>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody>
              <Stack spacing="24px">
                <Box>
                  <FormControl isRequired>
                    <FormLabel htmlFor="scrape_Id">Select Scrape</FormLabel>
                    {scrapesLoading ? (
                      "Loading Scrapes...."
                    ) : (
                       allScrapes && 
                        <Select id="scrape_id">
													{allScrapes.map((scrape) => (
														<option key={scrape.id} value={scrape.id}>
															{scrape.name}
														</option>
													))}
                      	</Select>
                    )}
                  </FormControl>
                </Box>

                <Box>
                  <Tag size="lg">
                    {selectedLinks.length + " Links Selected"}
                  </Tag>
                </Box>

                <Box>
                  <FormLabel htmlFor="removeScrapeEntryIds">
                    Delete Scrape Entries
                  </FormLabel>
                  <Textarea
                    id="removeScrapeEntryIds"
                    placeholder="Enter Scrape Entry Ids You Want To Delete"
                  />
                </Box>

              </Stack>
              <Button colorScheme="blue" onClick={saveScrape} mt={4}>
                Save Scrape
              </Button>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
    </>
  );
}
