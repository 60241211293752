import { gql } from "@apollo/client";

export const LINKDISCARD = gql`
  mutation linkDiscard($id: Int!) {
    linkDiscard(input: { id: $id }) {
      message
      errors
    }
  }
`;

export const LINKUNDISCARD = gql`
  mutation linkUndiscard($id: Int!) {
    linkUndiscard(input: { id: $id }) {
      message
      errors
    }
  }
`;

export const LINKSCREATE = gql`
  mutation linksCreate($urls: [String!], $excelFile: File, $cityId: Int!) {
    linksCreate(input: { urls: $urls, excelFile: $excelFile, cityId: $cityId }) {
      links {
        url
      }
      errors
      message
    }
  }
`;

export const SCRAPERUN = gql`
  mutation scrapeRun($scrapeId: Int!, $runMode: String) {
    scrapeRun(input: { scrapeId: $scrapeId, runMode: $runMode}) {
      message
			scrape {
				createdAt
				endedAt
				frequency
				id
				name
				scheduledAt
				scrapeEntriesCount
				startedAt
				updatedAt
			}
    }
  }
`;

export const SCRAPECANCEL = gql`
  mutation scrapeCancel($scrapeId: Int!) {
    scrapeCancel(input: { scrapeId: $scrapeId }) {
      message
      errors
    }
  }
`;

export const SCRAPECREATE = gql`
mutation scrapeCreate($name: String!, $linkIds: [Int!], $links: [String!], $frequency: String!, $scheduledAt: String, $linksFile: File, $cityId: Int){
  scrapeCreate(input: { name: $name, linkIds: $linkIds, links: $links, frequency: $frequency, scheduledAt: $scheduledAt, linksFile: $linksFile, cityId: $cityId}) {
    errors
    message
    scrape {
      name
    }
  }
}
`;

export const SCRAPEUPDATE = gql`
mutation scrapUpdate($scrapeId: ID!, $name: String, $frequency: String, $scheduledAt: String, $addLinkIds: [Int!], $removeScrapeEntryIds: [Int!]){
  scrapeUpdate(input: {scrapeId: $scrapeId, name: $name, frequency: $frequency, scheduledAt: $scheduledAt, addLinkIds: $addLinkIds, removeScrapeEntryIds: $removeScrapeEntryIds}){
    scrape{
      id
      name
    }
    message
    errors
  }
}`;

export const UPDATE_FPIMG_FETCHING = gql`
mutation updateFpimgFetching($id: Int!, $fetchFloorplanImages: Boolean!){
  updateFpimgFetching(input: { id: $id, fetchFloorplanImages: $fetchFloorplanImages}){
    errors
    message
  }
}`;

export const SCRAPE_LINK = gql`
mutation scrapeLink($id: Int!){
  scrapeLink(input: {id: $id}) {
    errors
    message
  }
}`;

export const Edit_SCRAPE_LINKS = gql`
mutation editScrapeLinks($scrapeId: Int!, $cityId: Int!, $addLinksArray: [String!], $removeLinksArray: [String!], $removeLinksIdsArray: [Int!], $linksFile: File){
  editScrapeLinks(input: { scrapeId: $scrapeId, cityId: $cityId, addLinksArray: $addLinksArray, removeLinkIdsArray: $removeLinksIdsArray, removeLinksArray: $removeLinksArray, linksFile: $linksFile }) {
    errors
    message
    scrape {
      id
      name
      scheduledAt
      scrapeEntries{
        link{
          url
          id
        }
      }
      scrapeEntriesCount
    }
  }
}`;

export const DELETE_SCRAPE_ENTRY = gql`
mutation deleteScrapeEntry($scrapeId: Int!, $scrapeEntryId: Int!){
  deleteScrapeEntry(input: { scrapeId: $scrapeId, scrapeEntryId: $scrapeEntryId}){
    errors
    message
    scrape {
      endedAt
      id
      name
      scrapeEntries{
        link{
          id
          url
        }
      }
      scrapeEntriesCount
    }
  }
}`;

export const TOGGLE_SCRAPE_DISCARD = gql`
mutation scrapeDiscard($scrapeId: Int!, $discard: Boolean!){
  scrapeDiscard(input: { scrapeId: $scrapeId, discard: $discard }) {
    errors
    message
  }
}`;