import { gql } from '@apollo/client';

export const ALL_CITIES = gql`
  query cities {
    cities {
      id
      name
    }
  }
`;


export const LINKS = gql`
  query links($cityId: Int) {
    links(cityId: $cityId, includeDiscarded: true) {
      edges {
        node {
          id
          name
          url
          sId
					fetchFloorplanImages
          city{
            name
          }
					partOfScrape
          discardedAt
					success
					notes
					lastScraped
        }
      }
    }
  }
`;

export const SCRAPES = gql`
  query scrapes($status: [Status!]) {
    scrapes(status: $status) {
      edges {
        node {
          id
          name
          scheduledAt
          startedAt
          frequency
          createdAt
          endedAt
          scrapeEntriesCount
					discardedAt
					avgRunTime
        }
      }
    }
  }
`;

export const SUMMARY = gql`
  query summary {
    summary {
      cityCount
      linkCount
      scrapeCount
    }
  }
`;

export const ALLSCRAPES = gql`
query allScrape{
  allScrape{
    id
    name
    frequency
    scheduledAt
  }
}`;

export const GET_SCRAPE_ENTRIES = gql`
query scrapeEntries($scrapeId: Int!){
  scrapeEntries(scrapeId: $scrapeId){
    id
    notes
    updatedAt
		link{
			id
			url
		}
  }
}`;


export const ALL_SCRAPES = gql`
query allScrape{
  allScrape {
    edges {
      node {
        endedAt
        frequency
        id
        name
        scheduledAt
				status
				avgRunTime
        # scrapeEntries {
        #  id
        #  linkId
        # }
        scrapeEntriesCount
        scrapeHistories {
					id
					scrapeId
          completedScrapeEntriesCount
          canceledScrapeEntriesCount
          scrapeEntriesCount
          startedAt
          endedAt
          status
					scrapeResult
					runTime
					avgRunTime
        }
        startedAt
        updatedAt
				discardedAt
      }
    }
  }
}`;

export const SCRAPE_HISTORY = gql`
query scrapeHistory($id: Int, $status: String){
  scrapeHistory(id: $id, status: $status) {
    canceledScrapeEntriesCount
    completedScrapeEntriesCount
    id
		scrapeId
		name
    retries
    scrapeEntriesCount
		runTime
		avgRunTime
    scrapeEntryHistories {
      id
      notes
      rawHash
      scrapeEntry {
        link {
          url
        }
      }
      scrapeEntryId
      scrapeHistoryId
      status
      updatedAt
    }
    startedAt
    status
  }
}`;
