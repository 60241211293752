import React, { useEffect, useState } from "react";
import { SCRAPECANCEL } from "../api";
import { useMutation } from "@apollo/client";
import moment from "moment";
import { FaBan } from "react-icons/fa";
import Countdown from 'react-countdown';

import {
  useToast,
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Progress,
  Box,
  StatHelpText,
  Button,
	Spacer,
	Tag
} from "@chakra-ui/react";

export default function CurrentScrape({ scrape, refetchScrapes }) {
	const [scrapeProgress, SetScrapeProgress] = useState(0);
	const [remainingScrapeTime, setRemainingScrapeTime] = useState(scrape.avgRunTime);
	const oneLinkScrapeTime = scrape.avgRunTime ? (scrape.avgRunTime/scrape.scrapeEntriesCount) : 0;
  const toast = useToast();

	useEffect(() => {
		if (scrape.scrapeEntriesCount && oneLinkScrapeTime){
			setRemainingScrapeTime((scrape.scrapeEntriesCount - scrape.completedScrapeEntriesCount - scrape.canceledScrapeEntriesCount)*oneLinkScrapeTime)
		}
		SetScrapeProgress((scrape.completedScrapeEntriesCount + scrape.canceledScrapeEntriesCount)*100 / scrape.scrapeEntriesCount)
	}, [scrape])

  const [scrapeCancel] = useMutation(SCRAPECANCEL, {
    onCompleted: (data) => {
			refetchScrapes();
      toast({
        title: data.scrapeCancel.message,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    },
  });

  function cancelClick() {
		console.log(scrape)
    scrapeCancel({
      variables: { scrapeId: Number(scrape.scrapeId) },
    });
  }

	const renderer = ({ hours, minutes, seconds, completed }) => {
		if (!completed) {
			if (hours > 0) return <span>{hours}h {minutes}m {seconds}s</span>;
			return <span>{minutes}m {seconds}s</span>;
		} else {
			return <></>
		}
	};
	

  return (
    <Box my={5}>
      <Stat
        px={{ base: 2, md: 4 }}
        py={"5"}
        shadow={"xl"}
        border={"1px solid"}
        borderColor={useColorModeValue("gray.800", "gray.500")}
        rounded={"lg"}
      >
        <Flex justifyContent={"space-between"}>
          <Box px={{ base: 2, md: 4 }} w={"full"}>
						<Box display='flex'  w={"full"}>
							<Box>
								<StatLabel fontWeight={"medium"}>
									Started at: {moment(scrape.startedAt).fromNow()}
								</StatLabel>
								<StatNumber fontSize={"2xl"} fontWeight={"medium"}>
									{scrape.name}
								</StatNumber>
							</Box>
							<Spacer />
							{
								remainingScrapeTime > 0 &&
								<Box>
									<Tag size='xl' fontSize={"3xl"} fontWeight={"medium"} p='2'>{<Countdown date={Date.now() + remainingScrapeTime*1000} renderer={renderer}/>}</Tag>
								</Box>
							} 
							<Spacer />
							<Box>
								<Stat>
									<Tag size='lg' variant='solid' colorScheme='green' fontSize={"2xl"} fontWeight={"medium"}>{scrape.completedScrapeEntriesCount} / {scrape.scrapeEntriesCount}</Tag>{' '}
									<Tag size='lg' variant='solid' colorScheme='red' fontSize={"2xl"} fontWeight={"medium"}>{scrape.canceledScrapeEntriesCount}</Tag>
								</Stat>
							</Box>
						</Box>
            <StatHelpText mt={2}>
              <Flex alignItems={"center"} justifyContent={"space-between"}>
                <Progress width={"100%"} hasStripe value={scrapeProgress ? scrapeProgress : 0} mr="1" />
                <Button
                  colorScheme="red"
                  variant="ghost"
                  size="lg"
                  onClick={cancelClick}
                >
                  <FaBan />
                </Button>
              </Flex>
            </StatHelpText>
          </Box>
        </Flex>
      </Stat>
    </Box>
  );
}
