import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import {
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Box,
  FormControl,
  FormLabel,
  Button,
  Textarea,
  Select,
} from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";

import { LINKSCREATE, LINKS } from "../../api";

export default function AddLinkPopover({ cities, currentCity }) {

  const [uploadedFile, setUploadedFile] = useState(null);
  const toast = useToast();

  const [linksCreate] = useMutation(LINKSCREATE, {
		context: { hasUpload: true },
    refetchQueries: [LINKS],
    onCompleted: (data) => {
      if (data.linksCreate.errors === null) {
        toast({
          title: data.linksCreate.message,
          status: "info",
          duration: 4000,
          isClosable: true,
        });
      } else if (data.linksCreate.errors !== null) {
        toast({
          title: data.linksCreate.error,
          status: "info",
          duration: 4000,
          isClosable: true,
        });
      }
    },
  });

  const mutationcall = () => {
    let urlsInput = document.getElementById("urls").value;
    let cityIdInput = document.getElementById("city").value;

    if ((urlsInput !== "" || uploadedFile) && cityIdInput !== "") {
      let urlArr = urlsInput
        .split(" ")
        .join("")
        .split(",")
        .filter((l) => l.length > 5);

			linksCreate({
        variables: {
          urls: urlArr.length > 0 ? urlArr : [],
          cityId: Number(cityIdInput),
					excelFile: uploadedFile ? uploadedFile : null
        },
      });

    } else {
      toast({
        title: "Invalid input, please enter urls and select city",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

	function handleFileUpload(file){
		console.log(file)
		setUploadedFile(file);
	}

  return (
    <Box>
      <Popover placement="bottom-end" isLazy>
        <PopoverTrigger>
          <Button colorScheme="blue">
            <FaPlus />
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverHeader fontWeight="semibold">
            Add Links
          </PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <FormControl isRequired>
              <FormLabel htmlFor="urls">URLs</FormLabel>
              <Textarea
                id="urls"
                size="lg"
                h={150}
                placeholder="Enter comma (,) separated URLs"
              />
							
              <FormLabel htmlFor="file" mt='3'>Upload Excel File To Add Links</FormLabel>
							<input id="fileSelect" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xls, .xlsx, .csv," onChange={(e) => handleFileUpload(e.target.files[0])}/>
            </FormControl>
            <FormControl mt={3} isRequired>
              <FormLabel htmlFor="cities">Select City</FormLabel>
              <Select id="city" placeholder="Select City" defaultValue={currentCity}>
                  {cities.map((city) => 
                      <option key={city.id} value={city.id} >
                        {city.name}
                      </option> 
                )}
              </Select>
            </FormControl>
            <Button colorScheme="blue" onClick={mutationcall} mt={3}>
              Save Links
            </Button>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
}
