import React, { useEffect, useState } from 'react';
import {
  Box,
	InputGroup,
	Input,
	InputRightElement,
	Button,
	Text,
} from '@chakra-ui/react'; 
import AuthUser from '../AuthUser';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";

export default function Login() {
	const toast = useToast();
	const Auth = React.useContext(AuthUser);
	const password = 'welcome23';
	const [inputValue, setInputValue] = useState(null);
	const navigate = useNavigate();

	let newUser = true;
	if (localStorage.getItem("auth_sprape_user") === "sprape_admin"){
		newUser = false;
		loginSuccess();
	}

	function loginSuccess(){
		Auth.setIsAuthorized(true);
		navigate("/");
		localStorage.setItem("auth_sprape_user", "sprape_admin");
		if (newUser){
			toast({
				title: 'Login Successful',
				status: 'success',
				duration: 5000,
				isClosable: true,
			})
		}
	};

	useEffect(() => {
		if (inputValue === password){
			loginSuccess();
		}
	}, [inputValue])

  return (
    <>
			<Box align='center'>
				<Box size='80%' mt='100px' maxW='400px' boxShadow='2xl' rounded='md' p='6'>
					<Text fontSize='1.8em' mb='3'>Log In</Text>
					<PasswordInput inputValue={setInputValue} setInputValue={setInputValue}/>
				</Box>
			</Box>
    </>
  );
}

function PasswordInput({inputValue, setInputValue}) {
  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)

  return (
    <InputGroup size='lg'>
      <Input
        pr='4.5rem'
        type={show ? 'text' : 'password'}
        placeholder='Enter password'
				onChange={e => setInputValue(e.target.value)}
      />
      <InputRightElement width='4.5rem'>
        <Button h='1.75rem' size='sm' onClick={handleClick}>
          {show ? 'Hide' : 'Show'}
        </Button>
      </InputRightElement>
    </InputGroup>
  )
}
