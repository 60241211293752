import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
  split,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';

import { createUploadLink } from 'apollo-upload-client';
import ActionCable from 'actioncable';
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink";

import config from './';

const cable = ActionCable.createConsumer(config.ACTION_CABLE_URL);
const actionCableLink = new ActionCableLink({ cable });

const options = {
  uri: config.REACT_APP_BACKEND_BASE_URL,
};

const httpLink = ApolloLink.split(
  (operation) => operation.getContext().hasUpload,
  createUploadLink(options),
  new HttpLink(options)
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  actionCableLink,
  httpLink
);

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: splitLink,
  name: 'sprape',
  version: '1.0',
});
