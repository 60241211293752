import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './config';
import { ChakraProvider } from '@chakra-ui/react';
import { Home } from './components';

function App() {
  return (
    <div className='App'>
      <ChakraProvider>
        <ApolloProvider client={apolloClient}>
          <Home />
        </ApolloProvider>
      </ChakraProvider>
    </div>
  );
}

export default App;
